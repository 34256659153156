<template>
    <div v-if="detailLoad">
        <b-row>
            <b-col offset-md="2" md="8" offset-lg="2" lg="8" sm="12">
                <div class="card p-4">
                    <div>
                        <b-row>
                            <b-col lg="10" md="10" sm="12">
                                <h4>
                                    <span
                                        class="text-capitalize">{{$t('title.load')}}#{{detailLoad.load_number}} </span>
                                    <span><small><re-status :status="detailLoad.status"></re-status></small></span>
                                </h4>
                                <h6>
                                    <span class="text-secondary">
                                        {{$global.utcDateToLocalDate(detailLoad.created_at) }}
                                    </span>
                                </h6>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" class="text-right">
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleCancelClick"
                                          v-b-tooltip.hover :title="$t('button.title.back')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="mt-3">
                        <div class="box border rounded p-4">
                            <h4><span class="text-capitalize">{{$t('title.load')}}: </span></h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.loadReferenceNumber')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.load_number}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.transportationCompany')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.supplier_carrier ? detailLoad.supplier_carrier.name : null}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.transportVehicle')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.transport_vehicle">
                                            <span v-if="detailLoad.transport_vehicle.truck_plate_number"> {{ detailLoad.transport_vehicle.truck_plate_number }} </span>
                                            <span v-if="detailLoad.transport_vehicle.trailer_plate_number"> | {{ detailLoad.transport_vehicle.trailer_plate_number }} </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.driverName')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.driver ? detailLoad.driver.name : ""}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->

                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.passportNumber')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.driver ? detailLoad.driver.passport_number : ""}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.status')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <re-status :status="detailLoad.status"></re-status>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.total')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.s_price}}
                                            <span v-if="detailLoad.currency && detailLoad.currency.label">
                                                {{detailLoad.currency.label}}
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.comments')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.comments}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>
                    <div class="mt-3">
                        <div class="box border rounded p-4">
                            <h4> <span class="text-capitalize">{{$t('title.loadDetails')}}: </span></h4>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.estimatedLoadingDate')}}</span></h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <span v-if="detailLoad.estimated_loading_time">
                                                {{$global.utcDateToLocalDate(detailLoad.estimated_loading_time)}}
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.actualLoadingDate')}}</span></h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <span v-if="detailLoad.actual_loading_time">
                                                {{$global.utcDateToLocalDate(detailLoad.actual_loading_time)}}
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.estimatedUnLoadingDate')}}</span></h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <span v-if="detailLoad.estimated_unloading_time">
                                                {{$global.utcDateToLocalDate(detailLoad.estimated_unloading_time)}}
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.expectedUnloadDate')}} ({{$t('title.calculated')}})</span></h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <span v-if="detailLoad.estimated_time_arrival_transit">
                                               {{$global.utcDateToLocalDate(detailLoad.estimated_time_arrival_transit)}}
                                            </span>
                                            <span v-else-if="detailLoad.estimated_time_arrival">
                                               {{$global.utcDateToLocalDate(detailLoad.estimated_time_arrival)}}
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.actualUnLoadingDate')}}</span></h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <span v-if="detailLoad.actual_unloading_time">
                                                {{$global.utcDateToLocalDate(detailLoad.actual_unloading_time)}}
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.transitLeadTime')}}</span></h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                             <span v-if="detailLoad.price">
                                                 {{detailLoad.price.lead_time_transport}} {{(detailLoad.price.lead_time_transport !== 1) ? $t('title.days') : $t('title.day')}}
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.pickupLeadTime')}}</span></h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                             <span v-if="detailLoad.price">
                                                {{detailLoad.price.lead_time_pickup}} {{(detailLoad.price.lead_time_pickup !== 1) ? $t('title.days') : $t('title.day')}}
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>
                    <div class="mt-3">
                        <div class="box border rounded p-4">
                            <a-tabs default-active-key="1">
                                <a-tab-pane key="1" :tab="$t('title.order')">
                                    <b-table
                                        striped
                                        :responsive="true"
                                        :items="detailLoad.spot_load_orders"
                                        :fields="fields"
                                    >
                                        <template #cell(order_id)="{item}">
                                            {{item.order.order_id}}
                                        </template>
                                        <template #cell(vin_number)="{item}">
                                            {{item.order.vin_number ? item.order.vin_number : ''}}
                                        </template>
                                        <template #cell(brand)="{item}">
                                            {{item.brand ? item.brand.title : ""}}
                                        </template>
                                        <template #cell(model)="{item}">
                                            <div>{{item.model ? item.model.title : ""}}</div>
                                            <div>{{item.model ? item.model.version_code : ""}}</div>
                                        </template>
                                        <template #cell(status)="{item}">
                                            {{item._status}}
                                        </template>
                                        <template #cell(load_status)="{item}">
                                            <ol-status :status="item.order.load_status"></ol-status>
                                        </template>
                                        <template #cell(from_location_id)="{item}">
                                            <print-ad :item="item.from_location" titled="1"></print-ad>
                                        </template>
                                        <template #cell(to_location_id)="{item}">
                                            <print-ad :item="item.to_location" titled="1"></print-ad>
                                        </template>
                                    </b-table>
                                </a-tab-pane>
                                <a-tab-pane key="2" :tab="$t('title.attachments')"
                                            v-if="detailLoad.spot_load_documents">
                                    <table class="table table-striped">
                                        <thead>
                                        <tr>
                                            <th width="20">#</th>
                                            <th width="120">{{$t('title.title')}}</th>
                                            <th width="80" class="text-right">{{$t('column.action')}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(document, index) in detailLoad.spot_load_documents">
                                            <td>{{index + 1}}</td>
                                            <td class="text-capitalize">{{document.title}}</td>
                                            <td class="text-right">
                                                <b-button :title="$t('button.download')"
                                                          v-b-tooltip.hover variant="outline-primary"
                                                          class="ml-2 ml-2 btn-sm"
                                                          v-if="document.document && document.document.download_url"
                                                          :disabled="!(document.document && document.document.download_url)"
                                                          :href="(document.document ? document.document.download_url : '')"
                                                          target="_blank">
                                                    <i class="fa fa-cloud-download"></i>
                                                </b-button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table><!--/.table.table-striped -->
                                </a-tab-pane>
                            </a-tabs>
                        </div><!--/.box-->
                    </div><!--/.mt-3-->
                </div><!--/.card-->
            </b-col><!--/.col-->
        </b-row><!--/.row-->
    </div><!--/div-->
</template>
<script>
    import {request} from "../../../util/Request";

    export default {
        props: ['id', 'handleCloseOperation'],
        data() {
            return {
                detailLoad: null,
                fields: [
                    {key: 'order_id', label: this.$t('column.orderId'), stickyColumn: true},
                    {key: 'vin_number', label: this.$t('column.vin')},
                    {key: 'brand', label: this.$t('column.brand')},
                    {key: 'model', label: this.$t('column.model')},
                    {key: 'from_location_id', label: this.$t('column.loadingPlace')},
                    {key: 'to_location_id', label: this.$t('column.unLoadingPlace')},
                    {key: 'status', label: this.$t('column.status')},
                    {key: 'load_status', label: this.$t('column.loadStatus')},
                ],
                items: []
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
                this.getDetail(this.$route.query.oToken)
            } else {
                this.serverError()
            }
        },
        methods: {
            async getDetail(id) {
                await request({
                    url: `/spot/loads/compound/supplier/detail/${id}`,
                })
                    .then((response) => {
                        const {data} = response
                        this.detailLoad = data
                    })
                    .catch((error) => {

                    })
            },
            handleCancelClick() {
                this.handleCloseOperation()
            }
        },
    }
</script>
