<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">{{$t('title.vehicleArrivals')}}</template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('spotloadsownview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>

                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="supplier-spot-table">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(supplier_id)="record">
                            {{record.item.supplier ? record.item.supplier.name : ''}}
                        </template>
                        <template v-slot:cell(transport_vehicle_id)="record">
                            <span v-if="record.item.transport_vehicle">
                                <span v-if="record.item.transport_vehicle.truck_plate_number"> {{ record.item.transport_vehicle.truck_plate_number }} </span>
                                <span v-if="record.item.transport_vehicle.trailer_plate_number"> | {{ record.item.transport_vehicle.trailer_plate_number }} </span>
                            </span>
                        </template>
                        <template v-slot:cell(driver_id)="record">
                            {{record.item.driver ? record.item.driver.name : ''}}
                        </template>
                        <template v-slot:cell(supplier_carrier_id)="record">
                            {{record.item.supplier_carrier ? record.item.supplier_carrier.name : ''}}
                        </template>
                        <template v-slot:cell(supplier_compound_id)="record">
                            {{record.item.supplier_compound ? record.item.supplier_compound.name : ''}}
                        </template>
                        <template v-slot:cell(orders)="record">
                            {{record.item.spot_load_orders ? record.item.spot_load_orders.length : 0}}
                        </template>
                        <template v-slot:cell(status)="record">
                            <ibl-status :label="record.item.status == 4 && record.item.supplier_carrier_reconfirm_at ? 'Confirmed' : false" :status="record.item.status"></ibl-status>
                        </template>
                        <template v-slot:cell(action)="record">
                            <div v-if="$global.hasRole('suppliercompound')">
                                <a @click="handleUnloadClick(record.item)"
                                   v-if="record.item.status >= 6 && record.item.status < 8"
                                   :title="$t('button.title.gateInConfirmation')"
                                   v-b-tooltip.hover class="text-success ml-1">
                                    <i class="fe fe-check-square"></i>
                                </a>
                                <a @click="setOperation('detail', record.item.id)"
                                   :title="$t('button.title.detailItem')" class=" ml-1"
                                   v-if="$global.hasPermission('spotloadsownview')"
                                   v-b-tooltip.hover>
                                    <i class="icmn-info"></i>
                                </a>
                            </div>
                        </template>
                        <template v-slot:row-details="{ item }">
                            <b-card>
                                <b-list-group flush>
                                    <b-list-group-item></b-list-group-item>
                                </b-list-group>
                            </b-card>
                        </template>
                    </b-table><!-- /.b-table -->
                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.supplier-compound-table-->
                <div class="supplier-compound-operation">
                    <a-drawer
                        placement="right"
                        :width="'90%'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="handleOperationReset"
                        :visible="selectedLoad !== null"
                        :zIndex="10"
                        :title="title"
                    >
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col sm="12" v-if="formFields.orders">
                                    <b-row v-for="(ilo, index) in formFields.orders" :key="index">
                                        <b-col sm="12">
                                            <h4 class="text-secondary">{{$t('title.order')}}#{{ilo.order.order_id}}</h4>
                                        </b-col>
                                        <b-col sm="6">
                                            <dl class="row">
                                                <dt class="col-sm-3">{{$t('column.fromLocation')}}</dt>
                                                <dd class="col-sm-9">
                                                    <span v-if="ilo.order.from_location">
                                                        <print-ad :item="ilo.order.from_location"></print-ad>
                                                    </span>
                                                </dd>
                                                <dt class="col-sm-3">{{$t('column.toLocation')}}</dt>
                                                <dd class="col-sm-9">
                                                    <span v-if="ilo.order.to_location">
                                                        <print-ad :item="ilo.order.to_location"></print-ad>
                                                    </span>
                                                </dd>
                                                <dt class="col-sm-3">{{$t('column.brand')}}/{{$t('column.model')}}</dt>
                                                <dd class="col-sm-9">
                                                    {{ilo.order.brand.title}}/{{ilo.order.model.title}}
                                                </dd>
                                                <dt class="col-sm-3 text-truncate">{{$t('column.importerNumber')}}</dt>
                                                <dd class="col-sm-9">{{ilo.order.invoice_number_importer}}</dd>
                                                <dt class="col-sm-3">{{$t('column.orderId')}}</dt>
                                                <dd class="col-sm-9">{{ilo.order.order_id}}</dd>
                                                <dt class="col-sm-3">{{$t('column.orderVin')}}</dt>
                                                <dd class="col-sm-9">{{ilo.order.vin_number}}</dd>
                                            </dl>
                                        </b-col>
                                        <b-col sm="6">
                                            <div class="form-group">
                                                <label class="d-block">{{$t('input.damage')}} *</label>
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.status"
                                                    :clearable="false"
                                                    placeholder=""
                                                    v-model="ilo.status"
                                                    :class="[{'invalid is-invalid': (formErrors.has('status'))}]"
                                                />
                                                <div class="invalid-feedback">{{formErrors.first('status')}}</div>
                                            </div><!-- /.form-group -->

                                            <b-row v-if="ilo.status == 9">
                                                <b-col sm="12" md="6" lg="6">
                                                    <b-form-group label="ETR" class="mb-0"
                                                                  :invalid-feedback="formErrors.first('etr')">
                                                        <b-form-datepicker
                                                            placeholder=""
                                                            v-model="ilo.etr"
                                                            :state="((formErrors.has('etr') ? false : null))"
                                                            class="mb-2"></b-form-datepicker>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col sm="12" md="6" lg="6">
                                                    <div class="form-group">
                                                        <b-form-group class="mb-0"
                                                            :label="$t('input.controllerName')"
                                                            label-for="controller_name"
                                                            :invalid-feedback="formErrors.first('controller_name')"
                                                        >
                                                            <b-form-input
                                                                id="controller_name"
                                                                v-model="ilo.controller_name"
                                                                type="text"
                                                                :state="((formErrors.has('controller_name') ? false : null))"
                                                                ref="controller_name"
                                                                @focus="$event.target.select()"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </div><!-- /.form-group -->
                                                </b-col>

                                                <!--# Start damages documents #-->
                                                <b-col lg="12" md="12" sm="12" class="mt-2 mb-3">
                                                    <b-card class="mb-0">
                                                        <b-card-header v-b-toggle="'damage-order-attachments-'+index"
                                                                       class="p-0">
                                                            <h4 class="mb-0">
                                                                <span class="badge badge-primary">
                                                                    {{$t('title.fileAttachments')}}
                                                                </span>
                                                            </h4>
                                                        </b-card-header><!-- /.p-0-->
                                                        <b-collapse :id="`damage-order-attachments-${index}`">
                                                            <div class="bg-light p-3">
                                                                <b-row>
                                                                    <b-col lg="4" md="4" sm="12">
                                                                        <div class="form-group">
                                                                            <b-form-group
                                                                                :label="$t('input.title')+' *'"
                                                                                label-for="title"
                                                                                :invalid-feedback="formErrors.first('title')"
                                                                            >
                                                                                <b-form-input
                                                                                    id="title"
                                                                                    v-model="ilo.damage_document.title"
                                                                                    type="text"
                                                                                    :state="((formErrors.has('damage_title_err') ? false : null))"
                                                                                    @focus="$event.target.select()"
                                                                                ></b-form-input>
                                                                            </b-form-group>
                                                                            <div class="invalid-feedback">
                                                                                {{formErrors.first('damage_title_err')}}
                                                                            </div>
                                                                        </div><!-- /.form-group -->
                                                                    </b-col><!--/b-col-->
                                                                    <b-col lg="4" md="4" sm="12">
                                                                        <b-form-group class="mt-4 pt-2">
                                                                            <upload
                                                                                v-model="ilo.damage_document.document"
                                                                                :disabled="ilo.damage_document.document"
                                                                                :title="(ilo.damage_document.document ? $t('msc.uploadedFile') : $t('msc.uploadFile'))"
                                                                                css-class="mt-0 btn-sm"></upload>
                                                                            <b-button :title="$t('msc.removeUpload')"
                                                                                      type="button"
                                                                                      variant="outline-primary"
                                                                                      v-b-tooltip.hover
                                                                                      class="ml-2 ml-2 btn-sm"
                                                                                      @click="() => {ilo.damage_document.document = null;}"
                                                                                      :disabled="!ilo.damage_document.document"
                                                                                      v-if="ilo.damage_document.document">
                                                                                <i class="fa fa-close"></i>
                                                                            </b-button>
                                                                            <b-button :title="$t('button.download')"
                                                                                      v-b-tooltip.hover
                                                                                      type="button"
                                                                                      variant="outline-primary"
                                                                                      class="ml-2 ml-2 btn-sm"
                                                                                      v-if="ilo.damage_document.document && ilo.damage_document.document.download_url"
                                                                                      :disabled="!(ilo.damage_document.document && ilo.damage_document.document.download_url)"
                                                                                      :href="(ilo.damage_document.document ? ilo.damage_document.document.download_url : '')"
                                                                                      target="_blank">
                                                                                <i class="fa fa-cloud-download"></i>
                                                                            </b-button>
                                                                        </b-form-group>
                                                                        <div class="invalid-feedback d-block">
                                                                            {{formErrors.first('damage_document_err')}}
                                                                        </div>
                                                                    </b-col><!--/b-col-->
                                                                    <b-col lg="2" md="2" sm="12">
                                                                        <div class="form-group">
                                                                            <label class="d-block">
                                                                                <pre> </pre>
                                                                            </label>
                                                                            <b-button variant="info" type="button"
                                                                                      size="sm"
                                                                                      class="btn-block"
                                                                                      @click="handleAddUpdateOrderDamageAttachmentClick(index)">
                                                                                <i class="fa fa-plus"></i>
                                                                            </b-button><!--/b-button-->
                                                                        </div><!-- /.form-group -->
                                                                    </b-col><!--/b-col-->
                                                                    <b-col lg="2" md="2" sm="12">
                                                                        <div class="form-group">
                                                                            <label class="d-block">
                                                                                <pre> </pre>
                                                                            </label>
                                                                            <b-button variant="warning" type="button"
                                                                                      size="sm"
                                                                                      class="btn-block"
                                                                                      @click="resetOrderDamageAttachment(index)">
                                                                                {{$t('button.reset')}}
                                                                            </b-button><!--/b-button-->
                                                                        </div><!-- /.form-group -->
                                                                    </b-col><!--/b-col-->
                                                                </b-row><!--/b-row-->
                                                                <b-row>
                                                                    <b-col cols="12">
                                                                        <table class="table table-bordered bg-white">
                                                                            <thead>
                                                                            <tr>
                                                                                <th width="50">#</th>
                                                                                <th width="180">{{$t('column.title')}}
                                                                                </th>
                                                                                <th width="60">{{$t('column.action')}}
                                                                                </th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            <tr v-for="(ct, ci) in ilo.damage_documents"
                                                                                :class="[{'table-primary': ct.token === ilo.document.token}]">
                                                                                <td>{{ci + 1}}</td>
                                                                                <td>
                                                                                    {{ct.title}}
                                                                                </td>
                                                                                <td>
                                                                                    <a @click="handleEditOrderDamageAttachmentClick(index, ct.token)"
                                                                                       :title="$t('button.title.editItem')"
                                                                                       v-b-tooltip.hover>
                                                                                        <i class="fe fe-edit"></i>
                                                                                    </a>
                                                                                    <a :title="$t('button.download')"
                                                                                       class=" ml-1"
                                                                                       :href="ct.document.download_url"
                                                                                       target="_blank"
                                                                                       v-b-tooltip.hover>
                                                                                        <i class="fa fa-cloud-download"></i>
                                                                                    </a>
                                                                                    <a-popconfirm title="Are you sure？"
                                                                                                  @confirm="handleDeleteOrderDamageAttachmentClick(index, ct.token)">
                                                                                        <i slot="icon"
                                                                                           class="fe fe-trash"></i>
                                                                                        <a class=" ml-1"
                                                                                           :title="$t('button.title.deleteItem')"
                                                                                           v-b-tooltip.hover>
                                                                                            <i class="fe fe-trash"></i>
                                                                                        </a>
                                                                                    </a-popconfirm>
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                            <tfoot
                                                                                v-show="ilo.documents.length <= 0">
                                                                            <tr>
                                                                                <th colspan="3">
                                                                                    {{$t('title.noDataAvailable')}}
                                                                                </th>
                                                                            </tr>
                                                                            </tfoot>
                                                                        </table><!-- /.table table-bordered -->
                                                                    </b-col><!--/b-col-->
                                                                </b-row><!--/b-row-->
                                                            </div><!-- /.bg-light -->
                                                        </b-collapse>
                                                        <!-- /#damage-order-attachments-->
                                                    </b-card><!-- /b-card -->
                                                </b-col><!--/b-col-->
                                            </b-row>
                                            <b-form-group
                                                :label="$t('input.comment')+' '"
                                                label-for="comments"
                                                :invalid-feedback="formErrors.first('comments')"
                                            >
                                                <b-textarea
                                                    id="comments"
                                                    v-model="ilo.comments"
                                                    :state="((formErrors.has('comments') ? false : null))"
                                                    @focus="$event.target.select()"
                                                ></b-textarea>
                                            </b-form-group>
                                        </b-col>

                                        <!--# Start unload order attachments #-->
                                        <b-col lg="12" md="12" sm="12" class="mt-3 mb-5">
                                            <b-card class="mb-0">
                                                <b-card-header v-b-toggle="'unload-order-attachments-'+index"
                                                               class="p-0">
                                                    <h4 class="mb-0">
                                                        <span class="badge badge-primary">
                                                            {{$t('title.fileAttachments')}}
                                                        </span>
                                                    </h4>
                                                </b-card-header><!-- /.p-0-->
                                                <b-collapse :id="`unload-order-attachments-${index}`">
                                                    <div class="bg-light p-3">
                                                        <b-row>
                                                            <b-col lg="4" md="4" sm="12">
                                                                <div class="form-group">
                                                                    <b-form-group
                                                                        :label="$t('input.title')+' *'"
                                                                        label-for="title"
                                                                        :invalid-feedback="formErrors.first('title')"
                                                                    >
                                                                        <b-form-input
                                                                            id="title"
                                                                            v-model="ilo.document.title"
                                                                            type="text"
                                                                            :state="((formErrors.has('title') ? false : null))"
                                                                            @focus="$event.target.select()"
                                                                        ></b-form-input>
                                                                    </b-form-group>
                                                                    <div class="invalid-feedback">
                                                                        {{formErrors.first('title')}}
                                                                    </div>
                                                                </div><!-- /.form-group -->
                                                            </b-col><!--/b-col-->
                                                            <b-col lg="4" md="4" sm="12">
                                                                <b-form-group class="mt-4 pt-2">
                                                                    <upload
                                                                        v-model="ilo.document.document"
                                                                        :disabled="ilo.document.document"
                                                                        :title="(ilo.document.document ? $t('msc.uploadedFile') : $t('msc.uploadFile'))"
                                                                        css-class="mt-0 btn-sm"></upload>
                                                                    <b-button :title="$t('msc.removeUpload')"
                                                                              type="button"
                                                                              variant="outline-primary"
                                                                              v-b-tooltip.hover
                                                                              class="ml-2 ml-2 btn-sm"
                                                                              @click="() => {ilo.document.document = null;}"
                                                                              :disabled="!ilo.document.document"
                                                                              v-if="ilo.document.document">
                                                                        <i class="fa fa-close"></i>
                                                                    </b-button>
                                                                    <b-button :title="$t('button.download')"
                                                                              v-b-tooltip.hover
                                                                              type="button"
                                                                              variant="outline-primary"
                                                                              class="ml-2 ml-2 btn-sm"
                                                                              v-if="ilo.document.document && ilo.document.document.download_url"
                                                                              :disabled="!(ilo.document.document && ilo.document.document.download_url)"
                                                                              :href="(ilo.document.document ? ilo.document.document.download_url : '')"
                                                                              target="_blank">
                                                                        <i class="fa fa-cloud-download"></i>
                                                                    </b-button>
                                                                </b-form-group>
                                                                <div class="invalid-feedback d-block">
                                                                    {{formErrors.first('document')}}
                                                                </div>
                                                            </b-col><!--/b-col-->
                                                            <b-col lg="2" md="2" sm="12">
                                                                <div class="form-group">
                                                                    <label class="d-block">
                                                                        <pre> </pre>
                                                                    </label>
                                                                    <b-button variant="info" type="button" size="sm"
                                                                              class="btn-block"
                                                                              @click="handleAddUpdateUnloadAttachmentClick(index)">
                                                                        <i class="fa fa-plus"></i>
                                                                    </b-button><!--/b-button-->
                                                                </div><!-- /.form-group -->
                                                            </b-col><!--/b-col-->
                                                            <b-col lg="2" md="2" sm="12">
                                                                <div class="form-group">
                                                                    <label class="d-block">
                                                                        <pre> </pre>
                                                                    </label>
                                                                    <b-button variant="warning" type="button" size="sm"
                                                                              class="btn-block"
                                                                              @click="resetUnLoadAttachment(index)">
                                                                        {{$t('button.reset')}}
                                                                    </b-button><!--/b-button-->
                                                                </div><!-- /.form-group -->
                                                            </b-col><!--/b-col-->
                                                        </b-row><!--/b-row-->
                                                        <b-row>
                                                            <b-col cols="12">
                                                                <table class="table table-bordered bg-white">
                                                                    <thead>
                                                                    <tr>
                                                                        <th width="50">#</th>
                                                                        <th width="180">{{$t('column.title')}}</th>
                                                                        <th width="60">{{$t('column.action')}}</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr v-for="(ct, ci) in ilo.documents"
                                                                        :class="[{'table-primary': ct.token === ilo.document.token}]">
                                                                        <td>{{ci + 1}}</td>
                                                                        <td>
                                                                            {{ct.title}}
                                                                        </td>
                                                                        <td>
                                                                            <a @click="handleEditUndLoadAttachmentClick(index, ct.token)"
                                                                               :title="$t('button.title.editItem')"
                                                                               v-b-tooltip.hover>
                                                                                <i class="fe fe-edit"></i>
                                                                            </a>
                                                                            <a :title="$t('button.download')"
                                                                               class=" ml-1"
                                                                               :href="ct.document.download_url"
                                                                               target="_blank" v-b-tooltip.hover>
                                                                                <i class="fa fa-cloud-download"></i>
                                                                            </a>
                                                                            <a-popconfirm title="Are you sure？"
                                                                                          @confirm="handleDeleteUnLoadAttachmentClick(index, ct.token)">
                                                                                <i slot="icon" class="fe fe-trash"></i>
                                                                                <a class=" ml-1"
                                                                                   :title="$t('button.title.deleteItem')"
                                                                                   v-b-tooltip.hover>
                                                                                    <i class="fe fe-trash"></i>
                                                                                </a>
                                                                            </a-popconfirm>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                    <tfoot
                                                                        v-show="ilo.documents.length <= 0">
                                                                    <tr>
                                                                        <th colspan="3">
                                                                            {{$t('title.noDataAvailable')}}
                                                                        </th>
                                                                    </tr>
                                                                    </tfoot>
                                                                </table><!-- /.table table-bordered -->
                                                            </b-col><!--/b-col-->
                                                        </b-row><!--/b-row-->
                                                    </div><!-- /.bg-light -->
                                                </b-collapse><!-- /#unload-order-attachments-->
                                            </b-card><!-- /b-card -->
                                        </b-col><!--/b-col-->
                                        <b-col sm="12">
                                            <hr/>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <div class="drawer-footer">
                                <b-button variant="warning" @click="handleOperationReset" class="mr-2"
                                          size="sm" :title="$t('button.title.cancel')" v-b-tooltip.hover>
                                    {{$t('button.cancel')}}
                                </b-button>
                                <b-button variant="primary" button="submit" type="filled"
                                          :disabled="global.pendingRequests > 0" size="sm"
                                          :title="$t('button.title.update')" v-b-tooltip.hover>
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px"
                                                 v-if="global.pendingRequests > 0"></clip-loader>
                                    {{$t('button.update')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!--/.supplier-compound-operation-->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.carriers')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.supplierCarriers"
                                            placeholder=""
                                            v-model="filters.supplierCarriers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.brands')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.brands"
                                            placeholder=""
                                            v-model="filters.brands"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.models')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.models"
                                            placeholder=""
                                            v-model="filters.models"
                                            @input="handleSelectModelsSelect"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.code')">
                                        <treeselect
                                            :multiple="true"
                                            :options=" _.uniqBy(_.filter(dropdowns.configCode, ({ model_id }) => _.every([_.includes(filters.models, model_id)])),'id')"
                                            placeholder=""
                                            v-model="filters.configCode"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.loadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="filters.loadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.unloadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="filters.unloadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.drivers')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.drivers"
                                            placeholder=""
                                            v-model="filters.drivers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.vehicles')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.vehicles"
                                            placeholder=""
                                            v-model="filters.vehicles"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.filterStatus"
                                            placeholder=""
                                            v-model="filters.filterStatus"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>
    </div>
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import StatusUpdateMixin from './StatusUpdateMixin'
    import Detail from './detail'

    const FORM_STATE = {
        id: null,
        orders: [],
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        configCode: [],
        drivers: [],
        vehicles: [],
        filterStatus: [],
        loadingPoint:[],
        unloadingPoint:[],
        supplierCarriers: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.loadNumber'),
            key: 'load_number',
            sortable: true,
        },
        {
            label: self.$t('column.supplierCarrier'),
            key: 'supplier_carrier_id',
            sortable: true,
        },
        {
            label: self.$t('column.transportVehicle'),
            key: 'transport_vehicle_id',
            sortable: true,
        },
        {
            label: self.$t('column.driver'),
            key: 'driver_id',
            sortable: true,
        },
        {
            label: self.$t('column.qty'),
            key: 'qty',
            sortable: false,
        },
        {
            label: self.$t('column.status'),
            key: 'status',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['spotloadsownview'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
                width: 150,
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, StatusUpdateMixin],
        components: {
            Treeselect,
            Detail,
        },
        data() {
            return {
                operationTitle: 'title.spotLoad',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'spot/loads/compound/supplier',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    countries: [],
                    brands: [],
                    models: [],
                    configCode: [],
                    drivers: [],
                    vehicles: [],
                    status: [{id: 9, label: this.$t('msc.yes')}, {id: 8, label: this.$t('msc.no')}],
                    filterStatus: [
                        {id: 4, label: this.$t('title.confirmed')},
                        {id: 5, label: this.$t('title.loaded')},
                        {id: 6, label: this.$t('title.inTransit')},
                        {id: 7, label: this.$t('title.unloaded')},
                    ],
                    supplierCarriers: [],
                    locations:[],
                },
                show: true,
                selectedLoad: null,
            }
        },
        mounted() {
            this.getTransportVehicles();
            this.getDrivers();
            this.getBrands();
            this.getModels();
            this.getLocations();
            this.getCountries();
            this.getSupplierCarriers()
            this.$title = this.$t('topBar.navigations.modules.vehicleArrivals')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: '/spot/loads/compound/supplier/update',
                        method: 'post',
                        data: {
                            id: this.selectedLoad.id,
                            orders: this.formFields.orders.map((item, index) => {
                                return {
                                    id: item.id,
                                    status: item.status,
                                    etr: (item.etr ? this.$global.dateToUtcDate(item.etr, 'YYYY-MM-DD', 'YYYY-MM-DD') : null),
                                    controller_name: item.controller_name,
                                    comments: item.comments,
                                    documents: item.documents,
                                    damage_documents: item.damage_documents
                                }
                            }),
                        },
                    })

                    this.itemUpdated()
                    this.handleOperationReset()
                    this.loadList()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.validationError()
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({id: item.id, label: `${item.label}`}))
                    this.dropdowns.configCode = data.map(item => ({
                        id: item.version_code,
                        label: item.version_code,
                        model_id: item.id
                    }))
                } catch (e) {
                    this.dropdowns.models = []
                    this.dropdowns.configCode = []
                }
            },
            async getDrivers() {
                try {
                    const response = await request({
                        url: '/dropdowns/drivers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.drivers = data
                } catch (e) {
                    this.dropdowns.drivers = []
                }
            },
            async getTransportVehicles() {
                try {
                    const response = await request({
                        url: '/dropdowns/transport/vehicles',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.vehicles = data
                    this.dropdowns.transportVehicles = data
                } catch (e) {
                    this.dropdowns.vehicles = []
                    this.dropdowns.transportVehicles = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = _.map(data,(i) => {
                        return{
                            id:i.id,
                            label:i.city,
                        }
                    })
                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            async getSupplierCarriers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCarriers = data

                } catch (e) {
                    this.dropdowns.supplierCarriers = []
                }
            },
            handleSelectModelsSelect(changeValue) {
                this.filters.configCode = []
            },
            handleOperationReset() {
                this.formFields = {...FORM_STATE}
                this.selectedLoad = null
            },
            hasListAccess() {
                return this.$global.hasPermission('spotloadsownview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80 {
        width: 80px;
    }

    .in-center {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    .order-select-chbx {
        position: absolute;
        right: -7px;
        top: -7px;
    }

    .add-load-btn {
        position: absolute;
        top: 12px;
        right: 50px;
    }

    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }

    [data-kit-theme="default"] .ant-input {
        height: 35px;
    }

    .ant-calendar-picker {
        width: 100%;
    }

    [data-kit-theme="default"] .ant-form-item-label {
        line-height: 26px !important;
    }

    .has-error .invalid-feedback {
        display: block;
    }
</style>
